import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { showModal } from 'tag/utils/ui-modal'
import PortalShoppingPassDashboardController from '../shopping-pass-dashboard';

@classic
class PortalShoppingPassDashboardIndexController extends PortalShoppingPassDashboardController {
  @action
  showModal(modalId) {
    showModal(modalId)
  }

  @action
  showOrderPhysicalCardModal() {
    this.setPinWidgetUrl()
    showModal('order_physical_card')
  }

  @action
  showChangeCardPinModal() {
    this.generateChangePinUrl()
    showModal('change_card_pin')
  }

  @action
  showActivateCardModal() {
    this.generateActivateCardUrl()
    showModal('activate_card_modal')
  }

  @action
  showDownloadMobileAppModal() {
    showModal('download-mobile-app-modal')
  }
}

export default PortalShoppingPassDashboardIndexController;
