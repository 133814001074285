import Service from '@ember/service'
import { service } from '@ember/service'
import { load as loadOnfido } from 'financeit-components/utils/load-onfido'

export default class OnfidoService extends Service {
  @service intl

  get sdkLocale() {
    return this.intl.primaryLocale.startsWith('fr') ? 'fr_FR' : 'en_US'
  }

  get hdSdkOptions() {
    return {
      crossDeviceClientIntroProductLogoSrc: `https://www.financeit.ca/assets/fi_public/project_loan/onfido/logo-${this.intl.language}.png`,
      language: {
        locale: this.sdkLocale,
        phrases: {
          cross_device_session_linked: {
            title: this.intl.t(`onfido-web-sdk.cross_device_session_linked.home_depot.title`),
            subtitle: this.intl.t('onfido-web-sdk.cross_device_session_linked.subtitle'),
            info: this.intl.t('onfido-web-sdk.cross_device_session_linked.info'),
            list_item_sent_by_you: this.intl.t('onfido-web-sdk.cross_device_session_linked.list_item_sent_by_you'),
            list_item_desktop_open: this.intl.t('onfido-web-sdk.cross_device_session_linked.list_item_desktop_open'),
          }
        },
      }
    }
  }

  async load() {
    try {
      await loadOnfido()
    } catch (error) {
      console.error('Onfido SDK failed to load in Onfido Ember Service:', error)
      throw error
    }
  }

  /**
   * @param {SdkOptions} options 
   * @param {boolean} useHdOptions
   */
  async initSdk(options, useHdOptions) {
    const baseSdkOptions = {
      containerId: 'onfido-mount',
      smsNumberCountryCode: 'CA',
      crossDeviceClientIntroProductLogoSrc: 'https://www.financeit.ca/assets/financeit-logo.svg',
      language: {
        locale: this.sdkLocale,
        phrases: {
          cross_device_session_linked: {
            title: this.intl.t(`onfido-web-sdk.cross_device_session_linked.financeit.title`),
            subtitle: this.intl.t('onfido-web-sdk.cross_device_session_linked.subtitle'),
            info: this.intl.t('onfido-web-sdk.cross_device_session_linked.info'),
            list_item_sent_by_you: this.intl.t('onfido-web-sdk.cross_device_session_linked.list_item_sent_by_you'),
            list_item_desktop_open: this.intl.t('onfido-web-sdk.cross_device_session_linked.list_item_desktop_open'),
          }
        },
      },
      ...options
    }

    const sdkOptions = useHdOptions ? Object.assign(baseSdkOptions, this.hdSdkOptions) : baseSdkOptions

    return window.Onfido.init(sdkOptions)
  }

  async getToken(borrowerId) {
    const url = `/${this.intl.language}/api/v3/partner/borrowers/${borrowerId}/onfido_sdk_token`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json'
        }
      })

      return res.json()
    } catch (error) {
      console.error('Failed to fetch Onfido SDK token:', error)
      throw error
    }
  }

  async createOnfidoCheck(borrowerId, callbackData) {
    const url = `/${this.intl.language}/api/v3/partner/borrowers/${borrowerId}/onfido_check`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(callbackData)
    })

    return res.json()
  }
}


/**
 * @typedef SdkOptions
 * @property {string} token
 * @property {string} containerId
 * @property {string[]} steps
 * @property {string} [smsNumberCountryCode]
 * @property {function} onComplete
 * @property {boolean} useModal
 */
