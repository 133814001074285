/* eslint-disable max-lines */
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { later } from '@ember/runloop'
import { State } from 'tag/utils/ui-uploader'
import { guidFor } from '@ember/object/internals'
import { inject as service } from '@ember/service'

export default class UiUploader extends Component {
  @service intl
  @service UiFileQueue
  @tracked state = State.Ready
  @tracked error
  @tracked uiFileQueue

  browseButtonId = `browse-button-${guidFor(this)}`
  fileInputId = `file-input-${guidFor(this)}`
  readyStateResetTimeout = 2000
  defaultMaxFileSize = '30MB'

  get buttonKind() {
    return this.args.buttonKind ?? 'primary'
  }

  get disabled() {
    return this.args.disabled === true || this.hasMaxUploadedFiles
  }

  get isReady() {
    return this.state === State.Ready && !this.isUploading
  }

  get isUploading() {
    return this.uiFileQueue.isUploading
  }

  get isComplete() {
    return this.state === State.Success
  }

  get hasError() {
    return this.state === State.Error
  }

  get isDragging() {
    return this.state === State.Dragging
  }

  get showOrSeparator() {
    return this.isReady && !this.error
  }

  get stateLabel() {
    if (this.isDragging) {
      return this.intl.t('uploader.dragging')
    } else if (this.isReady) {
      if (this.error) {
        return this.error
      } else {
        return this.intl.t('uploader.ready')
      }
    } else if (this.isUploading) {
      return this.intl.t('uploader.uploading')
    } else if (this.hasError) {
      return this.intl.t('uploader.has_error')
    } else if (this.isComplete) {
      return this.intl.t('uploader.success')
    }

    return ''
  }

  get files() {
    const argsFiles = (this.args.files ?? []).map(filename => ({
      name: filename,
      state: 'uploaded'
    }))

    return [
      ...argsFiles,
      ...this.uiFileQueue.files
    ]
  }

  get maxFiles() {
    if (this.args.multiple !== true) {
      return 1
    }

    return this.args.maxFiles ?? Infinity
  }

  get hasMaxUploadedFiles() {
    const uploadedFiles = this.files.filter(file => file.state === 'uploaded')
    return uploadedFiles.length >= this.maxFiles
  }

  get invalidFiletypeLabel() {
    return this.args.invalidFiletypeLabel ?? this.intl.t('uploader.invalid_filetype')
  }

  get maxFileSize() {
    const humanReadableSize = this.args.maxFileSize ?? this.defaultMaxFileSize
    const bytes = this.humanReadableSizeToBytes(humanReadableSize)

    if (!bytes) {
      throw new Error(`Invalid maxFileSize: ${humanReadableSize}`)
    }

    return bytes
  }

  get maxFileSizeLabel() {
    let humanReadableSize = this.args.maxFileSize ?? this.defaultMaxFileSize

    if (humanReadableSize.charAt(humanReadableSize.length - 1).toUpperCase() !== 'B') {
      humanReadableSize = `${humanReadableSize}B`
    }

    const filesizePieces = humanReadableSize.toUpperCase().match(/([0-9]+(\.[0-9]+)?)([BKMG])/)

    if (!filesizePieces) {
      throw Error(`UiUploader: Invalid human readable filesize: ${humanReadableSize}`)
    }

    const size = filesizePieces[1]
    const unit = filesizePieces[3]
    const translatedUnit = this.intl.t(`uploader.byte_size_abbreviation.${unit.toLowerCase()}`)

    return this.args.maxFileSizeLabel ?? this.intl.t('uploader.max_filesize', { filesize: `${size}${translatedUnit}` })
  }

  get uploadOnFileAdded() {
    return this.args.uploadOnFileAdded ?? true
  }

  get doShowFileList() {
    return this.args.showFileList ?? true
  }

  constructor() {
    super(...arguments)

    this.uiFileQueue = this.UiFileQueue.create(this.args.name, this.args.url)
    this.uiFileQueue.addListener(this)

    if (this.hasMaxUploadedFiles) {
      this.state = State.Success
    }
  }

  willDestroy() {
    super.willDestroy(...arguments)
    this.uiFileQueue.removeListener(this)
  }

  @action
  onFileAdded(file) {
    if (!this._isValidFile(file)) {
      this.error = this.invalidFiletypeLabel
      this.uiFileQueue.removeFile(file, this.args.headers)

      return this.setState(State.Ready)
    }

    if (file.size > this.maxFileSize) {
      this.error = this.maxFileSizeLabel

      this.uiFileQueue.removeFile(file, this.args.headers)

      return this.setState(State.Ready)
    }

    if (this.disabled) {
      this.uiFileQueue.removeFile(file, this.args.headers)

      return this.setState(State.Ready)
    }

    if (typeof this.args.onFileAdded === 'function') {
      this.args.onFileAdded(file)
    }

    if (this.uploadOnFileAdded) {
      this.startUploading(file)
    }
  }

  @action
  onDragStart(files) {
    const file = files[0]

    if (!this.disabled) {
      this.error = null

      if (file && !this._isValidFile(file)) {
        this.error = this.invalidFiletypeLabel
      } else {
        this.setState(State.Dragging)
      }
    }
  }

  @action
  onDragStop() {
    if (!this.disabled) {
      this.error = null
      this.setState(State.Ready)
    }
  }

  @action
  onDrop(files) {
    const file = files[0]

    if (!this.disabled) {
      if (file && !this._isValidFile(file)) {
        this.error = this.invalidFiletypeLabel
      }

      this.setState(State.Ready)
    }
  }

  @action
  bindBrowseButton() {
    const button = document.querySelector(`#${this.browseButtonId}`)

    /**
     * Binds the Enter and Space keys for a11y
     */
    button?.addEventListener('keyup', e => {
      const enterKey = 13
      const spaceKey = 32

      if (e.which === enterKey || e.which === spaceKey) {
        e.preventDefault()
        button.click()
      }
    })
  }

  @action
  onBrowseButtonClick() {
    document.querySelector(`#${this.fileInputId}`)?.click()
  }

  @action
  filterFile(file) {
    if (!this._isValidFile(file)) {
      this.error = this.invalidFiletypeLabel
      return false
    }

    return true
  }

  startUploading(file) {
    this.error = null
    this.uiFileQueue.uploadFile(file, this.args.headers)
  }

  onUploadSuccess(file, result) {
    this.setState(State.Success)

    if (typeof this.args.onUploadSuccess === 'function') {
      this.args.onUploadSuccess(file, result)
    }

    later(() => {
      this.setState(State.Ready)
    }, this.readyStateResetTimeout)
  }

  onUploadFail(error) {
    this.setState(State.Error)
    this.error = this.intl.t('uploader.has_error')
    if (typeof this.args.onUploadFail === 'function') {
      this.args.onUploadFail(error)
    }

    later(() => {
      this.setState(State.Ready)
    }, this.readyStateResetTimeout)
  }

  setState(newState) {
    this.state = newState
  }

  /**
   * Converts a human readable file size string into a bytes number
   *
   * @param {string} humanSize A human readable file size string, for example "0.9M" for 0.9 megabytes
   * @returns {number|null} If an invalid human readable string is passed, returns null, else returns the number in bytes
   */
  humanReadableSizeToBytes(humanSize) {
    if (typeof humanSize !== 'string') {
      throw new Error(`Invalid filesize value: ${humanSize}`)
    }

    const humanSizeParts = humanSize.match(/([0-9]+(\.[0-9]+)?)([GgMmKkBb])[Bb]?/)

    if (!humanSizeParts) {
      return null
    }

    const quantity = parseFloat(humanSizeParts[1])
    const qualifier = humanSizeParts[3].toLowerCase()

    switch (qualifier) {
      case 'g':
        return quantity * 1e+9
      case 'm':
        return quantity * 1e+6
      case 'k':
        return quantity * 1000
      case 'b':
        return quantity
      default:
        return null
    }
  }

  _isValidFile(file) {
    if (!this.args.accept) {
      return true
    }

    const fileTypeRegexes = this.args.accept
      .split(',')
      .map(typeString => {
        return new RegExp(typeString.replace(/\*/g, '.*'))
      })

    return fileTypeRegexes.some(regex => regex.test(file.type))
  }
}
