import { belongsTo, attr, hasMany } from "@ember-data/model";
import { isPresent } from "@ember/utils";
import LoanModel from "financeit-components/models/loan";
import { memberAction } from "ember-api-actions";
import { get } from "@ember/object";
import { inject as service } from "@ember/service";

export default class Loan extends LoanModel {
  @service intl;

  @belongsTo("quote") quote;

  @belongsTo("borrower") coborrower;
  @attr("string") bankAccountDigits;
  @attr("boolean") isPulled;
  @attr("boolean") isHardPulled;
  @attr("boolean") notReactivatable;
  @attr("boolean") noScoreBureauWithNoHardPull;
  @attr("boolean") flinksPoiAvailable;
  @attr("boolean") canChangeCreditLimit;
  @attr("boolean") isCustomWorkCompletionRule;
  @attr("string") directInviteRedirectUrl;
  @attr applicationPromoProgram;

  get approvedCoborrower() {
    if (
      isPresent(get(this, "coborrower.id")) &&
      !get(this, "coborrower.declined")
    ) {
      return this.coborrower;
    } else {
      return null;
    }
  }

  get isHomeImprovement() {
    return (
      isPresent(this.vertical) &&
      (get(this, "vertical.id") === 1 || get(this, "vertical.id") === 5)
    );
  }

  get isRetail() {
    return isPresent(this.vertical) && get(this, "vertical.id") === 4;
  }

  get isCreditTopUp() {
    return isPresent(this.vertical) && get(this, "vertical.id") === 11;
  }

  get hideFees() {
    if (
      (isPresent(this.hasAnyFeesAtAll) && !this.hasAnyFeesAtAll) ||
      this.feesAbsorbed
    ) {
      return true;
    }
    return (
      this.isRetail ||
      (this.isHomeImprovement &&
        get(this, "partner.allowQuebecLoans") &&
        this.fromQuebec)
    );
  }

  get termLessThanAmort() {
    return parseInt(this.term, 10) < parseInt(this.amortization, 10);
  }

  @attr("boolean") flinksAccountDetailBeingRequested;

  @attr("number") requestedAmountField;
  @attr("number") requestedAmountSlider;
  @attr("number") origRequestedAmount;
  @attr("number") originalAmortization;
  @attr("boolean") amountIsFixed;
  @attr("boolean") optedIntoInsurance;
  @attr("boolean") activeInsuranceDiscount;
  @attr("number") totalInsuranceAmount;
  @attr("number") periodicInsuranceAmount;
  @attr("number") insurancePaymentAmount;
  @attr("number") subventedInsurancePaymentAmount;
  @attr("boolean") createdFromQuote;

  // Client-only Attributes
  @attr("string") loginKey;

  @attr("number") fixedCost;
  @attr("number") borrowerSubventionPercentage;

  @attr("number") shoppingPassDuration;

  @attr("boolean") canReduceRequestedAmount;
  @attr("boolean") addCoborrowerAvailable;

  @attr("boolean") hasChangedHousingAndIncome;

  @attr("string") dbState;
  @attr paymentSchedule;
  @attr paymentScheduleWithInterestAdjustments;
  @attr paidScheduledLoanPayments;
  @attr transactionsTableData;
  @attr requestedFundsTableData;
  @attr("string") lender;
  @attr approvalConditions;
  @attr("boolean") inboundCallRequired;
  @attr("boolean") eligibleForSinResubmit;
  @attr vertical;
  @attr("boolean") isQuickFund;
  @attr("boolean") isRestructured;
  @attr("date") displayExpiryDate;
  @attr("number") spAnnualPercentageRate;
  @attr("boolean") fromQuebec;
  @attr("string") maxAmount;
  @attr("boolean") canChangeAmount;
  @attr subventionOptions;
  @attr("number") promo;
  @attr("string") province;
  @attr("number") totalIncome;
  @attr("number") totalDebt;
  @attr("number") limitMinRequestedAmount;
  @attr("number") minRate;
  @attr("number") maxRate;
  @attr("boolean") selectedTerm;
  @attr("boolean") approvalNoAmount;
  @attr("boolean") isJade;
  @attr("number") fee;

  @attr("date") fundingDate;
  @attr("date") firstPaymentDate;
  @attr("number") nextMinimumPayment;
  @attr nextScheduledPayment;
  @attr nextNonHolidayScheduledPayment;
  @attr("boolean") feesAbsorbed;
  @attr("boolean") noBankAccount;
  @attr("number") loanManagingGroupId;
  @attr("string") loanManagingGroupName;
  @attr("string") subventionType;
  @attr("string") subventionProgramName;
  @attr("boolean") isPurchaseDetailsCompleted;
  @attr("string") purchaseDetails;
  @attr("string") purchaseType;
  @attr("boolean") isSecureYourAccountStepCompleted;
  @hasMany("loanStep") loanSteps;
  @hasMany("loanTranche") loanTranches;
  @attr("string") financeitStepContent;

  @attr("string") displayGrade;

  @attr("number") borrowerId;
  @attr("boolean") borrowerIdVerified;
  @attr("boolean") coborrowerIdVerified;
  @attr("boolean") setUpPaymentsCompletedOrInReview;

  @attr("number") principalAmount;
  @attr("number") currentPrincipalBalance;
  @attr("number") requestedRate;
  @attr("date") termMaturesDate;

  @attr("number") fundingTranchesTotalRequestedAmount;
  @attr("string") latestIdsDocumentUrl;
  @attr("boolean") termMatchesAmort;
  @attr("number") todaysRate;
  @attr("number") borrowerRate;
  @attr("number") currentRate;
  @attr("number") postDeferralInterestRate;
  @attr("number") deferralPeriod;
  @attr("number") maxLoanTerm;
  @attr lastPayment;
  @attr("boolean") canMakeEarlyPayment;
  @attr currentArrears;
  @attr("boolean") paymentForLoanInArrears;
  @attr("string") amountNeededToPayOffLoan;
  @attr("boolean") fundedOrPartiallyFunded;
  @attr("boolean") isDisbursedOrClosed;
  @attr("boolean") isFinanceitCreditTopUp;
  @attr("boolean") isMarbleCreditTopUp;
  @attr("boolean") isMarbleLinkedCreditTopUp;
  @attr("boolean") isGoodStandingOrArrears;
  @attr("number") rate;
  @attr("boolean") hideAfterTermEndsModal;
  @attr("boolean") hidePostFundingDocuments;
  @attr flinksSummaries;
  @attr("string") bankingErrorMessage;
  @attr currentFlinksResponse;
  @attr("string") flinksSelectedInstitution;
  @attr("string") bankConnectParams;
  @attr("string") bankConnectUrl;
  @attr("string") bankDetailsRedirectUrl;
  @attr("string") changeBankDetailsRedirectUrl;
  @attr("number") borrowerFeePercentage;
  @attr amortLimits;
  @attr("boolean") extendedCredit;
  @attr("boolean") hdLoanBeforeEconsentDeployed;
  @attr applicationCreatedAt;
  @attr("boolean") wasShoppingPassFinalized;
  @attr("number") feeToDisplay;
  @attr("number") adminFee;

  @attr("boolean") alternativePoiAvailable;
  @attr("number") alternativePoiAmount;
  @attr("boolean") shoppingPassHovRequired;

  @attr("boolean") eligibleForPaymentScheduleChange;
  @attr("date") minPaymentDate;
  @attr("date") maximumPaymentDateWeekly;
  @attr("date") maximumPaymentDateBiweekly;
  @attr("date") maximumPaymentDateMonthly;
  @attr("string") formattedFrequency;
  @attr("boolean") paymentHolidayPresent;

  @attr archivedLoanAgreements;
  @attr("boolean") northStarStreamlinedFlowEligible;
  @attr('boolean') streamlinedFlowWithFeesDuringOpenPeriod;
  @attr("boolean") multiStageFinancing;
  @attr("boolean") multiStageFinancingFlowEligible;
  @attr("boolean") isRequestFundsNoWorkCompletionRule;
  @attr("boolean") isAwaitingAuthorizeReleaseOfFunds;

  @attr("number") nonSubventedRate;
  @attr("number") calculatedPrincipalAmount;
  @attr("number") paymentForRequestedRate;
  @attr("number") principalOnlyPercentage;
  @attr("date") forcedFinalizeDate;

  get hasCoborrower() {
    return isPresent(get(this, "coborrower.id"));
  }

  @attr("boolean") isReactivatable;

  get coborrowerStepExistsAndNotCompleted() {
    return this.approvedCoborrower && !this.coborrowerIdVerified;
  }

  get multiStageFinancingFundedButNotFinalized() {
    return this.state === 'msf_funded_but_not_finalized';
  }

  get approvedBorrowersFullNamesSeparatedByOr() {
    if (this.approvedCoborrower) {
      return `${get(this, "borrower.buildFullName")} ${this.intl.t("or")} ${get(
        this,
        "coborrower.buildFullName"
      )}`;
    }

    return get(this, "borrower.buildFullName");
  }

  get finalTrancheRequested() {
    return isPresent(this.loanTranches.lastObject) && this.loanTranches.lastObject.isMsfFinalTranche;
  }

  @attr("string") approvalExpiryText;

  @attr("boolean") hasSecureYourAccountStep;
  @attr("boolean") isSecureYourAccountStepMandatory;

  get stepToUseForSecureYourAccount() {
    if (this.isSecureYourAccountStepMandatory) {
      return "onfido_check";
    } else {
      return "verify_id";
    }
  }
}

Loan.prototype.changePaymentFrequencyOrAllDates = memberAction({
  path: "change_payment_frequency_or_all_dates",
  type: "post",
  urlType: "findRecord",
});

Loan.prototype.previewNewPaymentFrequencyAndDates = memberAction({
  path: "preview_new_payment_frequency_and_dates",
  type: "get",
  urlType: "findRecord",
});

Loan.prototype.togglePoi = memberAction({
  path: "toggle_poi",
});

Loan.prototype.getDocuments = memberAction({
  path: "documents",
  type: "get",
});

Loan.prototype.adjust = memberAction({
  path: "adjust",
  type: "put",
  urlType: "findRecord",
});
