import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { typeOf } from '@ember/utils';

class DateHelper extends Helper {
  @service
  intl;

  compute([date], hash) {
    const formatArg = hash.format;
    let format;

    if (typeOf(date) !== 'date') { date = new Date(date); }

    const short = this.intl.t('date.formats.direct_short');
    const long = this.intl.t('date.formats.direct_long');
    if ((typeOf(formatArg) === 'string') && (formatArg !== 'short')) {
      if (formatArg === 'long') { format = long; }
    } else {
      format = short;
    }

    // eslint-disable-next-line no-undef
    return moment(date).lang(this.intl.language).format(format);
  }
}

export default DateHelper;
