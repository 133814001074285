import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class MobileBrowserHelper extends Helper {

  @service
  mobile;

  get isMobileBrowser() {
    const mobileRegex = /(android|bb\d+|meego|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|playbook|silk|webos|tablet|nokia|zte|miui|redmi|oneplus|huawei|oppo|vivo|realme|samsung|motorola|lenovo|sony|htc|asus|alcatel|tcl|google|pixel)/i;
    return mobileRegex.test( navigator.userAgent || window.opera);
  }

  compute() {
    return this.isMobileBrowser;
  }
}
