import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'


export default class DownloadMobileAppModalComponent extends Component {
  id = 'download-mobile-app-modal'

  @action
  onCloseModal() {
    closeModal(this.id)
  }
}
