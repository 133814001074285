import Model, { attr } from '@ember-data/model'
import { memberAction } from "ember-api-actions";

export default class CreditTopUpOffer extends Model {
  @attr('number') maxOfferAmount
  @attr('number') minOfferAmount
  @attr('boolean') isExpired
  @attr('boolean') claimed
  @attr('boolean') isQuebec
  @attr('number') rateDiscount
  @attr('datetime') rateDiscountRemovedAt
}

CreditTopUpOffer.prototype.import = memberAction({
  path: "import",
  type: "post",
})

CreditTopUpOffer.prototype.attemptBureauRepull = memberAction({
  path: "attempt_bureau_repull",
  type: "post",
})

CreditTopUpOffer.prototype.holdForBureauRepull = memberAction({
  path: "hold_for_bureau_pull",
  type: "get",
})
