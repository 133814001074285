import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { closeModal } from 'tag/utils/ui-modal'
import { formatAsCurrency } from 'tag/utils/number-formatters'
import { inject as service } from '@ember/service'
import { action } from '@ember/object'

export default class ShoppingPassModalComponent extends Component {
  id = 'shopping_pass_modal'
  socketSubscription = null

  @service socket
  @service intl
  @service snowplow
  @service mobile

  @tracked scanned = false
  @tracked amount = 0
  @tracked transactionType = ''
  @tracked barcode = ''
  @tracked expiryCode = ''

  get displayAmount() {
    return formatAsCurrency(this.amount, { precision: 2 })
  }

  get availCredit() {
    return formatAsCurrency(this.args.shoppingPass.potentialAmountLeft, { precision: 2 })
  }

  get retryTransaction() {
    return (this.transactionType === 'network_error') || (this.transactionType === 'timeout')
  }

  get transactionStatusIconPath() {
    const icon = this.transactionStatus.icon
    return icon ? `/assets/consumer_direct/${icon}.png` : ''
  }

  get transactionStatus() {
    switch(this.transactionType) {
      case 'purchase':
        return {
          title: this.intl.t('notifications.purchase.title'),
          body: this.intl.t('notifications.purchase.body', { amount: this.displayAmount }),
          icon: 'offer-approved-hd'
        }
      case 'refund':
        return {
          title: this.intl.t('notifications.refund.title'),
          body: this.intl.t('notifications.refund.body', { amount: this.displayAmount }),
          icon: 'offer-approved'
        }
      case 'limit_decline':
        return {
          title: this.intl.t('notifications.limit_decline.title'),
          body: this.intl.t('notifications.limit_decline.body', { balance: this.availCredit }),
          icon: 'badge_caution'
        }
      case 'pass_disabled':
        return {
          title: this.intl.t('notifications.pass_disabled.title'),
          body: this.intl.t('notifications.pass_disabled.body'),
          icon: 'badge_caution'
        }
      case 'network_error':
        return {
          title: this.intl.t('notifications.network_error.title'),
          body: this.intl.t('notifications.network_error.body'),
          icon: 'badge_caution'
        }
      case 'timeout':
        return {
          title: this.intl.t('notifications.timeout.title'),
          body: this.intl.t('notifications.timeout.body'),
          icon: 'badge_caution'
        }
      default:
        return {
          title: '',
          body: '',
          icon: '',
        }
    }
  }

  onTransaction(data) {
    this.scanned = true
    this.amount = data.amount
    this.transactionType = data.type

    this.args.shoppingPass.reload()
    this.args.loan.reload()
  }

  getSingleUseCard() {
    this.args.shoppingPass.getSingleUseBarcode().then((data) => {
      this.barcode = data.barcode_image

      const rawDate = data.expiration_date
      const formattedDate = rawDate.slice(0, 2) + '/' + rawDate.slice(2, 4)
      this.expiryCode = formattedDate

      this.trackSingleUseCardGeneration(data)
    })
  }

  trackSingleUseCardGeneration(barcodeData) {
    const platformInfo = (() => {
      if (this.mobile.isIOSApp) {
        return {
          platform: 'iOS',
          appVersion: this.mobile.iosVersion,
        }
      } else if (this.mobile.isAndroidApp) {
        return {
          platform: 'android',
          appVersion: this.mobile.androidVersion,
        }
      }

      return {
        platform: 'web',
      }
    })()

    this.snowplow.snowplow('trackSelfDescribingEvent', {
      event: {
        schema: 'iglu:com.financeit/shopping_pass_generation_event/jsonschema/1-0-0',
        data: platformInfo
      },
      context: [{
        schema: 'iglu:com.financeit/shopping_pass_barcode_context/jsonschema/1-0-0',
        data: {
          token: barcodeData.token
        }
      }]
    })
  }

  @action
  onInsert() {
    this.socketSubscription = this.socket.subscribe({ channel: "ShoppingPassTransactionChannel", loan_id: this.args.loan.id }, {
      received: (data) => this.onTransaction(data)
    })
    this.getSingleUseCard()
  }

  @action
  reset() {
    this.scanned = false
    this.amount = 0
    this.transactionType = ''
    this.barcode = ''
    this.expiryCode = ''

    this.getSingleUseCard()
  }

  @action
  closeModal() {
    this.reset()
    closeModal(this.id)
  }

  willDestroy() {
    if (this.socketSubscription) {
      this.socketSubscription.unsubscribe()
    }

    super.willDestroy(...arguments)
  }
}
