import Model, { belongsTo, attr } from "@ember-data/model";
import { isPresent } from "@ember/utils";
import { get } from "@ember/object";
import { inject as service } from "@ember/service";
import { memberAction } from "ember-api-actions"

export default class LoanStep extends Model {
  @belongsTo("loan") loan;
  @attr("string") name;
  @attr("string") directState;
  @attr("boolean") isDirectCompleted;
  @attr("string") displayName;
  @attr("string") borrowerState;
  @attr("string") coborrowerState;

  @attr("string") borrowerSignUrl;
  @attr("string") coborrowerSignUrl;
  @attr("string") signedDocsUrl;
  @attr documentErrors;
  @attr uploadedDocuments;
  @attr("date") updatedAt;

  @attr("string") editLinkPath;

  @attr("boolean") wasInvalidated;
  @attr("boolean") canSignLoanAgreement;
  @attr("boolean") isEditable;
  @attr("boolean") signingStep;
  @attr("boolean") forAfterWorkCompletion;

  @attr("string") workCompletionTranslationKey;
  @attr("boolean") borrowerConfirmedWorkStarted;
  @attr("boolean") borrowerNeedsToConfirmWorkStarted;
  @attr("boolean") confirmedWorkComplete;
  @attr("boolean") authorizedReleaseOfFunds;
  @attr("boolean") visibleToConsumer;
  @attr("boolean") neverLockedInDirect;

  @service intl;

  get canShow() {
    const stepName = this.name;

    switch (stepName) {
      case "adjust_loan":
        return false;
      case "inbound_call":
        return get(this, "loan.isSecureYourAccountStepCompleted");
      default:
        return this.visibleToConsumer;
    }
  }

  get isNew() {
    return this.directState === "new";
  }

  get isCompleted() {
    return this.directState === "completed";
  }

  get isInFinanceitReview() {
    return this.directState === "financeit_review";
  }

  get isLocked() {
    if (this.neverLockedInDirect) {
      return false;
    }

    return isPresent(this.isEditable) && !this.isEditable;
  }

  get isInProgress() {
    return this.directState === "in_progress";
  }

  get isAlerted() {
    return this.directState === "alerted";
  }

  get stateIcon() {
    if (this.isCompleted) {
      return {
        name: "check-circle",
        color: "accent-100",
      };
    } else if (this.isLocked) {
      return {
        name: "lock",
        color: "ui-gray-100",
      };
    } else if (this.isAlerted) {
      return {
        name: "alert",
        color: "warning-100",
      };
    } else if (this.isInProgress || this.isInFinanceitReview) {
      return {
        name: "pending",
        color: "ui-gray-100",
      };
    } else {
      return {
        name: "circle",
        color: "ui-gray-100",
      };
    }
  }

  get stateDescription() {
    if (this.isCompleted) {
      return this.intl.t("loan_steps.completed");
    } else if (this.isAlerted) {
      return this.intl.t("loan_steps.action_required");
    } else if (this.isInProgress) {
      return this.intl.t("loan_steps.submitted");
    } else if (this.isInFinanceitReview) {
      return this.intl.t("loan_steps.under_review");
    } else {
      return null;
    }
  }

  get borrowerType() {
    if (this.name === "verify_id_coborrower") {
      return "coborrower";
    } else {
      return "borrower";
    }
  }

  get hasBorrowerCompleted() {
    return this.borrowerState === 'completed'
  }

  get hasCoborrowerCompleted() {
    return this.coborrowerState === 'completed'
  }
}

LoanStep.prototype.submit = memberAction({
  path: "submit",
  type: "put",
})
