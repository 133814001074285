import Service from '@ember/service';
import * as ActionCable from '@rails/actioncable'

// Uncomment to enabled client-side logging:
// ActionCable.logger.enabled = true

export default class WebsocketsService extends Service {
  consumer = ActionCable.createConsumer('/cable')

  subscribe(params, callbacks) {
    return this.consumer.subscriptions.create(params, callbacks)
  }
}
