import Controller from '@ember/controller'
import { action } from '@ember/object'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { tracked } from '@glimmer/tracking'
import { service } from '@ember/service'
import { debounce } from '@ember/runloop'

export default class CreditTopUpApplyController extends Controller {
  @service intl;
  @service router;
  @tracked showSpinner = false

  queryParams = ['offerId', 'source', 'counterOffer', 'editedOfferAmount']
  source = null;
  offerId = null;
  @tracked counterOffer = false
  @tracked editedOfferAmount = null

  @tracked offerAmount = this.editedOfferAmount ?? this.model.quote.amount
  @tracked amountInRange = true
  @tracked interestRate = null

  @action
  showCtuLearnMoreModal() {
    UiModalUtils.showModal('ctu-learn-more-modal')
  }

  @action
  showQuoteCardLearnMoreModal() {
    UiModalUtils.showModal('learn-more-modal')
  }

  @action
  onAmountChange(value) {
    if (value >= this.model.offer.minOfferAmount && value <= this.model.offer.maxOfferAmount) {
      this.offerAmount = value
      this.editedOfferAmount = value
      this.amountInRange = true
      debounce(this, this.calculate.bind(this), 200);
    } else {
      this.amountInRange = false
    }
  }

  calculate() {
    const quoteParams = this.model.quote.serialize()
    quoteParams.amount = this.offerAmount
    return this.model.quote.calculate(quoteParams).then(response => {
      this.model.quote.set('calculations', response)
      this.model.quote.set('amount', this.offerAmount)
    })
  }


  @action
  async createLoan() {
    this.showSpinner = true;

    try {
      await this.attemptBureauRepull();
    } catch {
      return this.router.transitionTo('credit-top-up.application-decline')
    }

    try {
      const response = await this.model.offer.import({ source: this.source, requested_amount: this.model.quote.amount })
      this.showSpinner = false
      if (response.rate_discount_removed){
        this.showSpinner = true
        await this.router.transitionTo('credit-top-up.apply', {
          queryParams: { offerId: this.offerId, counterOffer: true }
        })
        this.showSpinner = false;
        return;
      }
      if (response.backend_id) {
        return this.router.transitionTo('account.new', {
          queryParams: { backendId: response.backend_id },
        }).catch((error) => {
          if (error.name === 'TransitionAborted') {
            return;
          }
      });
      } else {
        return this.router.transitionTo('portal.hub', response.login_key).catch((error) => {
          if (error.name === 'TransitionAborted') {
            return;
          }
        });
      }
    } catch (error) {
      if (error.errors[0].meta.errors.credit_score_error) {
        return this.router.transitionTo('credit-top-up.application-decline', { queryParams: { creditScoreError: true }})
      } else {
        return this.router.transitionTo('credit-top-up.application-decline')
      }
    }
  }

  async attemptBureauRepull() {
    const response = await this.model.offer.attemptBureauRepull()

    if (response.repull) {
      await this.holdForBureauRepull()
    }
  }

  async holdForBureauRepull() {
    try {
      const response = await this.model.offer.holdForBureauRepull()
      if (response.status === 'keep_polling') {
        await new Promise(res => {
          setTimeout(res, 1000)
        });
        await this.holdForBureauRepull()
      }
    } catch {
      throw new Error('Bureau Error')
    }
  }
}
