import Component from '@glimmer/component'

export default class UiAccordionItemComponent extends Component {
  get isExpanded() {
    return this.args.active === this.args.id
  }

  get _font() {
    return this.args.titleFont ?? 'headline'
  }

  get _textSize() {
    return this.args.titleTextSize ?? 'lg'
  }

  get titleClasses() {
    const classes = [
      `tw-font-${this._font}`,
      `tw-text-${this._textSize}`,
    ]

    return classes.join(' ')
  }
}
